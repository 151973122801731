import React, { useEffect, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, orange } from '@mui/material/colors';
import Header from './components/Header';
import TimeLine, { Talk, TalkOrder } from './components/TimeLine';

// import talks from './fixtures/talks.json';
import Typography from '@mui/material/Typography/Typography';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useFirebase } from './components/FirebaseAppProvider';
import TalkForm from './components/TalkForm';
import { User } from 'firebase/auth';
import { Alert } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: orange[500],
      contrastText: '#fff',
    },
    secondary: {
      main: orange[100],
    },
  },
  typography: {
    fontFamily: 'poppins, sans-serif',
    allVariants: {
      color: grey[800],
    },
    h1: {
      fontSize: 64,
      fontWeight: 900,
    },
    h2: {
      fontSize: 38,
      fontWeight: 800,
    },
    h3: {
      fontSize: 28,
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'large',
        fullWidth: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontFamily: 'poppins, sans-serif',
          textTransform: 'none',
          fontWeight: 400,
        },
      },
    },
  },
});

function App() {
  const [user, setUser] = React.useState<User | undefined>(undefined);
  const [talks, setTalks] = React.useState<Talk[]>([]);
  const [submitTalkVisible, setSubmitTalkVisible] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState({status: false, message: '', severity: 'success'});
  const [talkDuration, setTalkDuration] = React.useState(0);
  const store = useFirebase();

  const MAX_TALK_DURATION = 60;
  const remainingTime = useMemo(
    () => MAX_TALK_DURATION - talkDuration,
    [talkDuration]
  );

  const firstDayOfThisMonth = useMemo(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  }, []);

  useEffect(() => {
    (async () => {
      const q = query(
        collection(store, 'tech-talks'),
        where('createdAt', '>=', firstDayOfThisMonth)
      );
      const querySnapshot = await getDocs(q);
      setTalks(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as unknown as Talk[]
      );
    })();
  }, [firstDayOfThisMonth, store]);

  const sortedTalks: Talk[] = useMemo(() => {
    const sortedTalksBeginning: any = [];
    const sortedTalksMiddle: any = [];
    const sortedTalksEnd: any = [];

    talks.map((talk: Talk) => {
      if (talk.order === TalkOrder.First) {
        sortedTalksBeginning.push(talk);
      }
      if (talk.order === TalkOrder.Middle) {
        sortedTalksMiddle.push(talk);
      }
      if (talk.order === TalkOrder.Last) {
        sortedTalksEnd.push(talk);
      }
    });

    return [...sortedTalksBeginning, ...sortedTalksMiddle, ...sortedTalksEnd];
  }, [talks]);

  useEffect(() => {
    let duration = 0;
    sortedTalks.map((talk) => (duration += Number(talk.talkLength)));
    setTalkDuration(duration);
  }, [sortedTalks]);

  return (
    <ThemeProvider theme={theme}>
      {showAlert?.status && <div
        style={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 10,
          left: 0,
          zIndex: 9999,
          width: '100%',
        }}
      >
        <Alert severity={'success'}>
          {showAlert?.message}
        </Alert>
      </div>}
      <Header
        onSubmitTalkClick={() => setSubmitTalkVisible(true)}
        availableSlots={talkDuration < MAX_TALK_DURATION}
      />
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '1024px',
          margin: '0 auto',
        }}
      >
        <Typography variant="h2" component="div" align="center">
          Schedule
        </Typography>
        <TalkForm
          visible={submitTalkVisible}
          handleClose={() => setSubmitTalkVisible(false)}
          setTalks={setTalks}
          user={user}
          setUser={setUser}
          firstDayOfThisMonth={firstDayOfThisMonth}
          remainingTime={remainingTime}
          setShowAlert={setShowAlert}
        />
        <TimeLine
          talks={sortedTalks}
          user={user}
          setTalks={setTalks}
          firstDayOfThisMonth={firstDayOfThisMonth}
          setShowAlert={setShowAlert}
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
