import React from 'react';
import {Box,Button,Grid,Stack,Typography} from '@mui/material';

interface HeaderProps {
  availableSlots?: boolean;
  onSubmitTalkClick: () => void;
}

const Header:React.FC<HeaderProps> = ({ availableSlots, onSubmitTalkClick }) => {
  return (
    <div>
      <Grid
        container
        marginBottom={'2rem'}
        justifyContent="center"
        alignItems="center"
        margin={'2rem'}
      >
        <Grid item xs={8} alignContent="center">
          <Box display="flex" justifyContent="center">
            <Stack spacing={3} maxWidth={600} >
              <Typography variant="h1" component="div" >
              Vehikl Lightning Talks ⚡
              </Typography>
              <Typography variant="h3" gutterBottom component="div">
                At tech talks, the Vehikl community comes together to discuss new technologies, encounters, and ideas that they came across the past month. 
              </Typography>
              <Stack spacing={2} direction="row" sx={{width: '100%', maxWidth: '35em'}}>
                <Button variant="contained" onClick={onSubmitTalkClick} disabled={!availableSlots}>Submit a talk</Button>
                <Button variant="outlined" href='#talks'>View Schedule</Button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <img src="../../images/lego-2.png" alt="" />
        </Grid>
      </Grid>
    </div>);
};
export default Header;
