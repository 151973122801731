import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  User,
} from 'firebase/auth';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFirebase } from './FirebaseAppProvider';
import { Talk, TalkLength, TalkOrder } from './TimeLine';

interface TalkFormProps {
  visible: boolean;
  handleClose: () => void;
  setTalks: (talks: Talk[]) => void;
  user?: User;
  setUser: (user: User | undefined) => void;
  firstDayOfThisMonth?: Date;
  remainingTime: number;
  setShowAlert?: (showAlert: any) => void;
}

const TalkForm: React.FC<TalkFormProps> = ({
  visible,
  handleClose,
  setTalks,
  user,
  setUser,
  firstDayOfThisMonth,
  remainingTime,
  setShowAlert,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<Talk>();
  const store = useFirebase();

  const onSubmit = async (data: Talk) => {
    const submitData = {
      ...data,
      email: user?.email,
      createdAt: new Date(),
    };
    await addDoc(collection(store, 'tech-talks'), submitData);

    (async () => {
      const q = query(
        collection(store, 'tech-talks'),
        where('createdAt', '>=', firstDayOfThisMonth)
      );
      const querySnapshot = await getDocs(q);
      setTalks(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as unknown as Talk[]
      );

      setShowAlert?.({
        status: true,
        message: 'Talk submitted successfully',
      });
  
      setTimeout(() => {
        setShowAlert?.({
          status: false,
          message: '',
        });
      }
      , 3000);
    })();
    handleClose();
  };

  const provider = useMemo(() => new GoogleAuthProvider(), []);
  const auth = useMemo(() => getAuth(), []);

  const [error, setError] = React.useState<string | undefined>(undefined);

  const handleLogIn = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      setError('Something went wrong');
      console.error(error);
    }
  };

  const handleLogOut = useCallback(() => {
    auth.signOut();
    setUser(undefined);
  }, [auth]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user?.email?.includes('@vehikl.com')) {
        setUser(user);
      }

      if (!user?.email) {
        setError('You must be a member of the Vehikl team to submit a talk');
        handleLogOut();
      }
    });
  }, [auth, handleLogOut]);

  return (
    <>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            height: user ? '90%' : ' 10%',
            overflowY: 'scroll',
            padding: 4,
          }}
        >
          {!user ? (
            <div>
              <Typography variant="body1" padding={'5px'}>
                {error}
              </Typography>
              <Button onClick={handleLogIn} variant="contained">
                Log in
              </Button>
            </div>
          ) : (
            <form
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
              }}
            >
              <Typography variant="h3" textAlign={'center'}>
                Submit a talk
              </Typography>
              <Divider />
              <Controller
                name="name"
                control={control}
                defaultValue={user?.displayName || ''}
                render={({ field }) => (
                  <TextField
                    label={'Username'}
                    value={field.value}
                    placeholder={'John Doe'}
                  />
                )}
              />
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label={'Presentation Title'} />
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={2}
                    {...field}
                    label={'Presentation Description'}
                  />
                )}
              />
              <Controller
                name="talkLength"
                defaultValue={TalkLength.Ten}
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Estimated Length of Talk</FormLabel>
                    <RadioGroup {...field} name="radio-buttons-group">
                      {remainingTime >= 5 && (
                        <FormControlLabel
                          value={TalkLength.Five}
                          control={<Radio />}
                          label="5 Minutes"
                        />
                      )}
                      {remainingTime >= 10 && (
                        <FormControlLabel
                          value={TalkLength.Ten}
                          control={<Radio />}
                          label="5 - 10 Minutes"
                        />
                      )}
                      {remainingTime >= 15 && (
                        <FormControlLabel
                          value={TalkLength.Fifteen}
                          control={<Radio />}
                          label="10 - 15 Minutes"
                        />
                      )}
                      {remainingTime >= 20 && (
                        <FormControlLabel
                          value={TalkLength.Twenty}
                          control={<Radio />}
                          label="15 - 20 Minutes"
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                )}
              />
              <Controller
                name="order"
                defaultValue={TalkOrder.First}
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel>Order Preference</FormLabel>
                    <RadioGroup {...field} name="radio-buttons-group">
                      <FormControlLabel
                        value={TalkOrder.First}
                        control={<Radio />}
                        label={TalkOrder.First}
                      />
                      <FormControlLabel
                        value={TalkOrder.Middle}
                        control={<Radio />}
                        label={TalkOrder.Middle}
                      />
                      <FormControlLabel
                        value={TalkOrder.Last}
                        control={<Radio />}
                        label={TalkOrder.Last}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
              <Controller
                name="consentForPublicTalk"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControl>
                    <Typography variant="body1" fontStyle="italic">
                      Your tech talk may be recorded and made public on our Tech
                      Talk website. If you would not like your talk shared
                      publicly, please click the checkbox below
                    </Typography>
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label="I would not like my talk to be shared publicly on the tech talk website."
                    />
                  </FormControl>
                )}
              />
              <Stack spacing={2} direction="row">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
                <Button
                  onClick={() =>
                    reset({
                      name: user?.displayName || '',
                      title: '',
                      description: '',
                      talkLength: TalkLength.Ten,
                      order: TalkOrder.First,
                      consentForPublicTalk: false,
                    })
                  }
                  variant={'outlined'}
                  disabled={isSubmitting}
                >
                  Reset
                </Button>
              </Stack>
            </form>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default TalkForm;
