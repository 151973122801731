import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { FirebaseContext } from './components/FirebaseAppProvider';

const firebaseConfig = {
  apiKey: 'AIzaSyDEP7s5JRR3JEig9lFQxRHnUq25z4Yi6LI',
  authDomain: 'lightning-talks-709c9.firebaseapp.com',
  projectId: 'lightning-talks-709c9',
  storageBucket: 'lightning-talks-709c9.appspot.com',
};

//initialize app
const firebaseApp = initializeApp(firebaseConfig);

console.log(firebaseApp);
// export const db = getFirestore();

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={firebaseApp}>
      <App />
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
