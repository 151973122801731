import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { User } from 'firebase/auth';
import {
  getFirestore,
  doc,
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFirebase } from './FirebaseAppProvider';
import { Talk, TalkLength, TalkOrder } from './TimeLine';

interface TalkFormProps {
  talk: Talk | undefined;
  handleClose: () => void;
  setTalks?: (talks: Talk[]) => void;
  user?: User;
  firstDayOfThisMonth?: Date;
}

const EditTalkForm: React.FC<TalkFormProps> = ({
  talk,
  handleClose,
  setTalks,
  user,
  firstDayOfThisMonth,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<Talk>();
  const store = useFirebase();

  const onSubmit = async (data: Talk) => {
    const submitData = { ...data, email: user?.email, createdAt: new Date() };

    const db = getFirestore();
    const docRef = doc(db, 'tech-talks', talk?.id);
    //update tech-talks collection
    await updateDoc(docRef, submitData);

    (async () => {
      const q = query(
        collection(store, 'tech-talks'),
        where('createdAt', '>=', firstDayOfThisMonth)
      );
      const querySnapshot = await getDocs(q);
      setTalks?.(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as unknown as Talk[]
      );
    })();
    handleClose();
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          height: user ? '90%' : ' 10%',
          overflowY: 'scroll',
          padding: 4,
        }}
      >
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
          }}
        >
          <Typography variant="h3" textAlign={'center'}>
            Edit talk
          </Typography>
          <Divider />
          <Controller
            name="name"
            control={control}
            defaultValue={user?.displayName || ''}
            render={({ field }) => (
              <TextField
                label={'Username'}
                value={field.value}
                placeholder={'John Doe'}
              />
            )}
          />
          <Controller
            name="title"
            control={control}
            defaultValue={ talk?.title || ''}
            render={({ field }) => (
              <TextField {...field} label={'Presentation Title'} />
            )}
          />
          <Controller
            name="description"
            control={control}
            defaultValue={ talk?.description || ''}
            render={({ field }) => (
              <TextField
                multiline
                rows={2}
                {...field}
                label={'Presentation Description'}
              />
            )}
          />
          <Controller
            name="talkLength"
            defaultValue={talk?.talkLength || TalkLength.Ten}
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Estimated Length of Talk</FormLabel>
                <RadioGroup {...field} name="radio-buttons-group">
                  <FormControlLabel
                    value={TalkLength.Ten}
                    control={<Radio />}
                    label="5 - 10 Minutes"
                  />
                  <FormControlLabel
                    value={TalkLength.Fifteen}
                    control={<Radio />}
                    label="10 - 15 Minutes"
                  />
                  <FormControlLabel
                    value={TalkLength.Twenty}
                    control={<Radio />}
                    label="15 - 20 Minutes"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
          <Controller
            name="order"
            defaultValue={talk?.order || TalkOrder.First}
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Order Preference</FormLabel>
                <RadioGroup {...field} name="radio-buttons-group">
                  <FormControlLabel
                    value={TalkOrder.First}
                    control={<Radio />}
                    label={TalkOrder.First}
                  />
                  <FormControlLabel
                    value={TalkOrder.Middle}
                    control={<Radio />}
                    label={TalkOrder.Middle}
                  />
                  <FormControlLabel
                    value={TalkOrder.Last}
                    control={<Radio />}
                    label={TalkOrder.Last}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
          <Controller
            name="consentForPublicTalk"
            control={control}
            defaultValue={talk?.consentForPublicTalk || false}
            render={({ field }) => (
              <FormControl>
                <Typography variant="body1" fontStyle="italic">
                  Your tech talk may be recorded and made public on our Tech
                  Talk website. If you would not like your talk shared publicly,
                  please click the checkbox below
                </Typography>
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label="I would not like my talk to be shared publicly on the tech talk website."
                />
              </FormControl>
            )}
          />
          <Stack spacing={2} direction="row">
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              disabled={isSubmitting}
            >
              Save
            </Button>
            <Button
              onClick={() =>
                reset({
                  name: user?.displayName || '',
                  title: '',
                  description: '',
                  talkLength: TalkLength.Ten,
                  order: TalkOrder.First,
                  consentForPublicTalk: false,
                })
              }
              variant={'outlined'}
              disabled={isSubmitting}
            >
              Reset
            </Button>
          </Stack>
        </form>
      </Card>
    </Modal>
  );
};

export default EditTalkForm;
