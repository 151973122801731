import { FirebaseApp } from 'firebase/app';
import { createContext, useContext } from 'react';
import { getFirestore } from 'firebase/firestore';

export const FirebaseContext = createContext<undefined | FirebaseApp>(undefined);

export const useFirebase = () => {
  const firebaseApp = useContext(FirebaseContext);
  if (firebaseApp === undefined) {
    throw new Error('useFirebase must be used within a FirebaseAppProvider');
  }
  const store = getFirestore(firebaseApp);
  return store;
};