import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { User } from 'firebase/auth';
import { collection, deleteDoc, doc, getDocs, query, Timestamp, where } from 'firebase/firestore';
import React from 'react';
import { AiOutlineDelete, AiOutlineEdit} from 'react-icons/ai';
import EditTalkForm from './EditTalkForm';
import { useFirebase } from './FirebaseAppProvider';


export enum TalkLength {
  Five = 5,
  Ten = 10,
  Fifteen = 15,
  Twenty = 20,
}

export enum TalkOrder {
  First = 'Near the Beginning',
  Middle = 'Some time in the Middle',
  Last = 'Near the End',
}

export interface Talk {
  id?: any;
  name: string;
  email: string;
  description?: string;
  time: string;
  order?: TalkOrder;
  title: string;
  talkLength: TalkLength;
  createdAt: Timestamp;
  tags?: string[];
  consentForPublicTalk?: boolean;
}
interface TimeLineProps {
  talks: Talk[];
  user?: User;
  setTalks?: (talks: Talk[]) => void;
  firstDayOfThisMonth?: Date;
  setShowAlert?: (showAlert: any) => void;
}

const TimeLine: React.FC<TimeLineProps> = ({ talks, user, setTalks, firstDayOfThisMonth, setShowAlert }) => {

  const [editTalkForm, setEditTalkForm] = React.useState(false);
  const [talkToEdit, setTalkToEdit] = React.useState<Talk | undefined>(undefined);

  const store = useFirebase();
  const deleteTalk = async (talk: Talk) => {
    await deleteDoc(doc(store, 'tech-talks', talk.id));

    setShowAlert?.({
      status: true,
      message: 'Talk deleted successfully',
    });

    setTimeout(() => {
      setShowAlert?.({
        status: false,
        message: '',
      });
    }
    , 3000);
    

    (async () => {
      const q = query(
        collection(store, 'tech-talks'),
        where('createdAt', '>=', firstDayOfThisMonth)
      );
      const querySnapshot = await getDocs(q);
      setTalks?.(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as unknown as Talk[]
      );
    })();
  };

  const editTalk = async (talk: Talk) => {
    setTalkToEdit(talk);
    setEditTalkForm(true);
  };

  return talks.length > 0 ? (
    <>
      {editTalkForm && (
        <EditTalkForm
          talk={talkToEdit}
          setTalks={setTalks}
          handleClose={() => {setEditTalkForm(false);}}
          user={user}
          firstDayOfThisMonth={firstDayOfThisMonth}
        />
      )}
      <Timeline sx={{ justifyContent: 'center' }} position="right" id="talks">
        {talks.map((talk) => {
          return (
            <TimelineItem key={talk.title}>
              <TimelineOppositeContent sx={{ flex: 0, padding: 0 }}>
                {talk.time}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box
                  bgcolor={grey[100]}
                  borderRadius={'16px'}
                >
                  {talk?.email == user?.email && (
                    <div style={{display:'flex', justifyContent: 'flex-end', padding:'0px 10px', height: '15px'}}>
                      <AiOutlineEdit className="icon" onClick={() => editTalk(talk)} />
                      <AiOutlineDelete onClick={() => deleteTalk(talk)} className="icon"/>
                    </div>
                  )}
                  <Box
                    display="grid"
                    p={2}
                    gridTemplateColumns={'1fr auto'}
                  >
                    <Typography>{talk.name}</Typography>
                    <Typography sx={{ justifySelf: 'end' }}>
                      {talk.talkLength} minutes
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        marginBottom: '8px',
                        fontSize: 'large',
                      }}
                    >
                      {talk.title}
                    </Typography>
                    <Typography sx={{ justifySelf: 'end' }}>
                      {talk.tags?.join(' , ')}
                    </Typography>
                    <Typography>{talk.description}</Typography>
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </>
  ) : (
    <Typography variant="h4" component="div" align="center">
      {' '}
      No talks have been scheduled yet.{' '}
    </Typography>
  );
};

export default TimeLine;
